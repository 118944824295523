export const Imagegallery = () => {
    return (
        <section class="roservice">
            <div class="container">
                <h2>RO Care Repair Center   RO Repair &amp; RO Installation Repair Provider</h2>
                <div class="row">
                    <div class="col-md-4">
                        <div class="service1">
                            <img src="img/imagegallery/rorepair.jpg" class="img-fluid" alt="ro service in patna" />
                            <div class="backser">
                                <h3>Ro Repair</h3>
                                <p>Regular servicing of your RO system is crucial to maintain its performance and longevity. Our trained technicians in patna are equipped to handle comprehensive RO servicing, including filter replacement, membrane cleaning, and checking for any leaks or malfunctions. We offer scheduled maintenance plans tailored to your needs, ensuring your RO system continues to deliver safe and pure drinking water.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="service1">
                            <img src="img/imagegallery/roinstall.jpg" class="img-fluid" alt="ro repair in patna" />
                            <div class="backser">
                                <h3>Ro Installation</h3>
                                <p>Installing a Reverse Osmosis (RO) system is essential for ensuring clean and safe drinking water in your home or office. At RO Care Service, we provide professional RO installation in patna to help you set up your water purification system quickly and efficiently.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="service1">
                            <img src="img/imagegallery/roservice.jpg" class="img-fluid" alt="aquaguard ro service in patna" />
                            <div class="backser">
                                <h3>Ro Parts Repair</h3>
                                <p>Facing issues with your RO system? Our expert repair in patna address a wide range of problems such as low water flow, unusual noises, leaks, or system failures. We diagnose the issue accurately and provide prompt repairs using genuine parts to restore your RO system's functionality quickly and efficiently. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}