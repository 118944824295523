import React from 'react';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='container'>
        <p>
            For RO Repair in Patna &amp; Home Visit Charge @299 Only. Best RO Repair Center in Patna. Trained Technicians.
        </p>
      </div>
    </header>
  )
}
