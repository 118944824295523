export const Footer = () => {
    return (
        <footer>

            <div className="ourlocation">
                <div className="container">
                    <p><i>Disclaimer :- We are third Party Service Provider &amp; We are independent customer service providers &amp; have no association or affiliation with third party brands. We are not using any brands name we are only service provider. Third party trademarks, logos, brand names, products, &amp; services are used only for references for informative aim only. </i></p>
                    <p><i>We Provide Service all location in Patna </i></p>
                    <a href="/">Home</a> |
                    <a href="privacypolicy">Privacy Policy</a>
                </div>
            </div>

            <div className="fix-phone">
                <p><a href="tel:8409607928">Free Home Visit Call : 8409607928</a></p>
            </div>
        </footer>
    )
}