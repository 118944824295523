import React from "react";

export const Process = () => {
    return (
        <div className="solution" id="process"> 
	<div className="container">
		<div className="row">
			
			<div className="col-md-6 col-xs-12">
				<div className="filter">
					<div className="filter-img"><img src="img/blogo.png" className="img-fluid" alt="ro repair water purifier" /></div>
					 
				</div>
			</div>

			<div className="col-md-6 col-xs-12">
				<h2><strong>About US</strong></h2>

				<div className="sernum">
					<div className="para">Aquafine RO Services is a leading provider of high-quality water purification services in Patna and the surrounding areas. Specializing in the maintenance, repair, and installation of Reverse Osmosis (RO) systems, Aquafine ensures that your home or office has access to clean and safe drinking water. With a strong commitment to customer satisfaction, Aquafine offers a variety of services, including regular servicing, filter changes, and troubleshooting of water purifier systems. </div>
				</div>
				<div className="sernum">
					<div className="para"><strong>Service Location:</strong> Patna and Nearby Areas Aquafine RO Services primarily serves Patna, the capital city of Bihar, and extends its expertise to nearby locations such as Danapur, Khagaul, Kankarbagh, Rajendra Nagar, and other residential and commercial areas in and around Patna. The company has built a reputation for reliability and efficiency in providing high-quality water purification solutions to both urban and rural areas in the region.</div>
				</div>

				{/*
				<div className="sernum">
                    <div className="number">1</div>
					<div className="para">If you have problem in your RO like water taste is hassy or TDS problem call us for technician visit. </div>
				</div>

				<div className="sernum">
                    <div className="number">2</div>
					<div className="para">If any part of your RO is not working, then our technician puts a new part in it. and Repair Also. </div>
				</div>

				<div className="sernum">
                    <div className="number">3</div>
					<div className="para">If you want spare parts or AMC plan for 1year or 2years call us, We provide RO warrent include all parts in you RO. </div>
				</div>

				<div className="sernum">
                    <div className="number">4</div>
					<div className="para">If your RO filter is not working properly or not filtering water, change your RO filter for pure &amp; healthy water. Membrane should be changed every 2 years or if you notice a significant drop in water passing through the RO system.  </div>
				</div>	
				*/}
				 
			</div>

		</div>
	</div>
</div>
    )
}