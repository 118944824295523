import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";
import Home from "./Home";
import { Header } from "./components/header";
import { Navigation } from "./components/navigation";
import { Footer } from "./components/footer";

import SmoothScroll from "smooth-scroll";
import { Privacypolicy } from "./components/privacypolicy";

function App() {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div className="App">
      <Header />
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/privacypolicy" element={<Privacypolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
