export const Achive = () => {
    return (
        <div className="expert" id="expert">
        <div className="container">
        <div className="row">
            <div className="col-xs-12 col-md-6">
                <img src="img/wedo/waterro2.png" className="img-fluid roimg1" alt="ro repair service center" />
            </div>

            
            <div className="col-xs-12 col-md-6">
            <div className="roimg2">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-md-6">
                        <div className="whymrwater">
                            <div className="imgicon">
                                <img src="img/wedo/clock.png" alt="ro repair in Patna" />
                            </div>
                            <div className="icontext">
                                <h5>Get Repair within 4 hours</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="whymrwater">
                            <div className="imgicon">
                                <img src="img/wedo/guarantee-v2.png" alt="ro service in bangalore/bengaluru" />
                            </div>
                            <div className="icontext">
                                <h5>30 Day Repair Guarantee</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="whymrwater">
                            <div className="imgicon">
                                <img src="img/wedo/insurance.png" alt="ro repair in bangalore/bengaluru" />
                            </div>
                            <div className="icontext">
                                <h5>Upto Rs. 1,474 AMC Plan</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="whymrwater">
                            <div className="imgicon">
                                <img src="img/wedo/price.png" alt="kent customer number" />
                            </div>
                            <div className="icontext">
                                <h5>Fixed Rate for spare parts</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="whymrwater">
                            <div className="imgicon">
                                <img src="img/wedo/service-v2.png" alt="ro customer care number" />
                            </div>
                            <div className="icontext">
                                <h5>2,542 Repair completed</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="whymrwater">
                            <div className="imgicon">
                                <img src="img/wedo/trained.png" alt="ro phone number in bangalore/bengaluru" />
                            </div>
                            <div className="icontext">
                                <h5>Fully Expert Technician</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
        </div>
    )
}